<template>
    <div class="overview-builder builder flex-container" >
        <div class="cards-container custom-scrollbar">
            <lesson-card v-for="(component, index) in components" :key="index"
                :component="component" />
        </div>
        <span v-if="components && components.length < 1">{{$t('noContentToDisplayMessage')}}</span>
        <properties-panel :save-component="saveOverview"/>
    </div>
</template>
<script>
import PropertiesPanel from '@/components/BackOffice/PropertiesPanel.vue'
import BuilderMixin from '@/views/BackOffice/BuilderMixin.js'
import LessonCard from '@/components/BackOffice/OverviewBuilder/LessonCard.vue'

export default {
    name: 'OverviewBuilder',
    mixins:[BuilderMixin], 
    components:{
        PropertiesPanel,
        LessonCard
    },
    computed:{
        components(){
            return this.$store.state.unitOverviewBuilderComponent.lessons
        }, 
        component(){
            return this.$store.state.currentBackOfficeComponent
        }
    },
    methods:{

        saveLessonComponent(component){
            if(!this.validateComponentFields(component))
                return
            if(component.audioOverview && component.audioOverview.id)
                this.setComponentPropertyValue(component, component.audioOverview.id, 'audioOverviewId')
            !component.essentialQuestionOverview.id
                ? this.$store.dispatch('createEssentialQuestion', component.essentialQuestionOverview)
                .then((response)=>{
                    let essentialQuestion = { ...component.essentialQuestionOverview}
                    essentialQuestion.id =  response.data
                    this.setComponentPropertyValue(component, essentialQuestion, 'essentialQuestionOverview')
                    this.setComponentPropertyValue(component, response.data,'essentialQuestionOverviewId')
                    this.$store.dispatch('saveLessonOverviewChanges', component)
                }) 
                : this.$store.dispatch('updateEssentialQuestion', component.essentialQuestionOverview)
                .then(()=>{
                    this.setComponentPropertyValue(component, component.essentialQuestionOverview.id,
                        'essentialQuestionOverviewId')
                    this.$store.dispatch('saveLessonOverviewChanges', component)
                })
        }, 
        saveOverviewComponent(component){
            if(!this.validateComponentFields(component))
                return
            this.$store.dispatch('updateUnitOverview', component)
        },
        addAlerts(component){
            component.errors.forEach(error => {
                let errorNotification = this.$store.state.notifications
                    .find(e => e.message === error)
                if(!errorNotification){
                    this.$store.commit('addSnackBarNotification', {
                        message: error, 
                        dismissable: true
                    })
                }
            });
        },
        validateComponentFields(component){
            if(!component)
                return false
            if(component.errors.length > 0){
                this.addAlerts()
                return false
            }
            return true
        },  
        setComponentPropertyValue(component, value, propertyName){
            this.$store.commit('setBuilderComponentPropertyValue',
                    {component: component, propertyName: propertyName, value: value})
        },
        saveOverview(){
            this.components.map((e)=>{
                this.saveLessonComponent(e)
            })
            this.saveOverviewComponent(this.$store.state.unitOverviewBuilderComponent)
        },
        //On ESC set overview as current component to set Background
        updateCurrentComponent(){
            this.$store.commit('updateCurrentBackOfficeComponent',
                 this.$store.state.unitOverviewBuilderComponent)
        },
        keydown(e){
            if(e.keyCode == 27)
                this.updateCurrentComponent()
        }
    },
    created(){
        this.$store.dispatch('loadUnitOverviewBuilderComponent', this.$route.params.unitId)
            .then(()=>{
                this.updateCurrentComponent()
            })
        this.$store.commit('updateSwitchButtonDisplayStatus', false)
        this.$store.commit('updateContentSectionState', true)
    }, 
    mounted(){
        this.updateCurrentComponent();
        document.addEventListener("keydown",this.keydown);
    },
    destroyed(){
        document.removeEventListener("keydown", this.keydown);
    }
}
</script>
<style lang="less" scoped>

.overview-builder{
    margin-top: 104px;
    height: calc(100vh - 104px);
    .cards-container{
        max-height: calc(100vh - 104px);
        overflow-y: auto;
    }
    > span{
        align-self: center;
        margin: auto;
        height: 28px;
        width: auto;
        color: rgba(0,0,0,0.6);
        font-family: 'Roboto';
        font-size: 19.64px;
        letter-spacing: 0.5px;
        line-height: 28px;
    }
}

</style>



