<template>
    <div class="lesson-card flex-container" @click="updateCurrentComponent">
        <image-upload :component="component"  :options="options"/>
        <div class="card-body flex-container"  :class="{'component-active': isActive}">
            <span class="legend">{{name}}</span>
            <input type="text" v-model="name"  :placeholder="$t('titlePlaceHolder')">

            <ckeditor :editor="editor"  :placeholder="$t('textPlaceHolder')" 
                v-model="description" :config="editorConfig"></ckeditor>

            <input type="text" v-model="question"  :placeholder="$t('essentialQuestionPlaceHolder')">
        </div>
    </div>
</template>
<script>
import ImageUpload from '@/components/BackOffice/ComponentSetters/ImageUpload.vue'
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
export default {
    name: 'LessonCard', 
    mixins:[BackOfficeComponentMixin], 
    components:{
        'image-upload' : ImageUpload
    },
    computed:{
        name:{
            get(){

                return this.component.name[this.locale]
            }, 
            set(value) {
                this.setComponentPropertyValue(value, 'name', this.locale)
            }
        },
        description:{
            get(){
                return this.component.description[this.locale] || "";
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'description', this.locale)
            }
        },
        question:{
            get(){
                return this.component.essentialQuestionOverview.question[this.locale]
            }, 
            set(value){
                this.$store.commit('setBuilderComponentPropertyValue',{
                    component: this.component.essentialQuestionOverview,
                    propertyName: 'question', 
                    value: value, 
                    language: this.locale
                })
            }
        },
        options(){
            return {name: 'image-details',  text:'addImageText', model:'imageId'}
        }
    }
}
</script>
<style lang="less">
    .lesson-card{
        cursor: pointer;
        .image-upload{
            height: 144px;
            width: 224px;
            background-color: #EFEFEF;
            margin: auto;
            flex-direction: column;
                :global(.delete-icon){
                    order: 0;
                    margin-top: 8px;
                    height: 16px;
                    max-width: 16px;
                    margin-left: auto;
                }

                :global(.image){
                    order: 1;
                    height: 64px;
                    width: 80px;
                    max-height: 64px;
                    max-width: 80px;
                    margin: 11px auto;
                }
                :global(span){
                    order:2;
                    margin: -3px auto;
                    text-align: center;
                }
        }
        .card-body.flex-container{
            flex-direction: column;
        }
        .card-body{

            &.component-active{
                background-color: #FFFFFF;
                box-shadow: var(--secondary-shadow);
            }
            
            margin-right: 10px;
            min-height: 306px;
            width: 464px;
            border-radius: 4px;
            background-color: #F6F7FA;
            box-shadow: var(--primary-shadow);
            margin-top: 16px;
            margin-left: 16px;

            >span{
                margin-right: 16px;
                margin-left: 16px;
                margin-top: 26px;
            }

            input{
                min-width: 432px;
                max-width: 406px;
                height: 40px;
                resize: none;
                color: rgba(0,0,0,0.6);
                font-family: 'Roboto', sans-serif;
                font-size: 12px;
                font-style: italic;
                letter-spacing: 0.4px;
                line-height: 16px;
                margin-top: 8px;
                margin-left: 16px;
                padding-left: 16px;
            }
            .ck.ck-editor{
                margin-left: 16px;
                margin-top: 8px;
                width: 432px;
                .ck-content{
                    height: 96px;
                }
            }
        }
    }

</style>


